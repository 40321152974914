
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { healthTop, healthMiddle, healthLast, healthDefault } from '@/core/utils'
import router from '@/router/clean';
import { etc, rule, notification, common } from '@/store/stateless';
import Validations from '@/core/services/etc/Validations';
import { store } from "@/store";

export default defineComponent({
    setup() {
        const ruleFormRef = ref<FormInstance>()

        const isSubmitted = ref(false)
        const top = ref()
        const middle = ref()
        const last = ref()
        const familyRef =ref()

        const healthDef = ref({
            id: null,
            name: null,
            email: null,
            contact_person: null,
            phone_number: null,
            phone_country_code: "+971",
            other_contact_info:null,
            insured_city: null,
            insured_type_id: 0,
            insurance_type: 0,
            is_salary: null,
            expected_insurance_start_date: null,
            customer_source: null,
            is_health: 1,
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            utm_content: null,
            dob: null,
            nationality_id: null,
            gender: null,
            is_married: false,
            company_name: null,
            is_self: null,
            agent: null,
            health_lead_map_id: 0,
            members: [{
                id: null,
                member_name: null,
                relationship: null,
                gender: null,
                dob: null,
                nationality_id: null,
                is_salary: null,
                declaration_answers: [],
                is_married: false
            }],
            categories: [{
                id: null,
                name: null,
                groups: [{
                    id: null,
                    insurance_provider_id: null,
                    insurance_providers: store.state.etc.providers,
                    insurance_policy_id: null,
                    insurance_policies: [],
                    group_count: 1,
                    visa_emirates: null
                }]
            }]
        })
        const reset = () => {
            Customers.setHealth(healthDef.value)
            ruleFormRef.value?.clearValidate()

        }

        const hideEmployeeType = ref(true)

        const health = computed(() => {
            return store.state.customer.health;
        })

        console.log('in New',health.value)

        const loading = computed(() => {
            return store.state.customer.detailLoading
        })
        
        const clickLoading = computed(() => {
            return store.state.customer.loading
        })

        const rules = reactive({
                name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
                email: [{ validator: Validations.email, trigger: ['blur', 'change'] }],
                phone_number: [{ validator: Validations.phone_number, trigger: ['blur', 'change'] }],
                dob: [{ validator: Validations.dob, trigger: ['blur', 'change'] }],
                gender: [{ validator: Validations.gender, trigger: ['blur', 'change'] }],
                is_salary: [{ validator: Validations.is_salary, trigger: ['blur', 'change'] }],
                is_self: [{ validator: Validations.is_self, trigger: ['blur', 'change'] }],
                age_group: [{ validator: Validations.age_group, trigger: ['blur', 'change'] }],
                insured_city: [{ validator: Validations.insured_city, trigger: ['blur', 'change'] }],
                insured_type_id: [{ validator: Validations.insured_type_id, trigger: ['blur', 'change'] }],
                insurance_type: [{ validator: Validations.insurance_type, trigger: ['blur', 'change'] }],
                company_name: [{ validator: Validations.company_name, trigger: ['blur', 'change'] }],
                expected_insurance_start_date: [{ validator: Validations.expected_insurance_start_date, trigger: ['blur', 'change'] }],
                nationality_id: [{ validator: Validations.nationality, trigger: ['blur', 'change'] }],
                // agent: [{ validator: Validations.agent, trigger: ['blur', 'change'] }]
            })

        const submitHealth = async (formEl: FormInstance | undefined) => {
            isSubmitted.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if (valid) {
                    health.value.members = health.value.members.reverse(); 
                    const result = await Customers.updateLead(health.value);
                    if (result.status == 200) {
                        notification.success(
                            'Customer',
                            'Customer has been updated successfully'
                        );
                        reset();
                        formEl.resetFields();
                        goLists(result.data.data.customer.id);
                    } else {
                        const { email_taken, phone_taken } = result.response.data.data
                        Validations.email_taken = email_taken
                        Validations.phone_taken = phone_taken
                        formEl.validate(async (valid) => {
                        })
                    }
                } else {
                    rule.customerDetails =  Object.keys(fields)[0]
                    console.log('rules',rule.customerDetails)
                    if (healthTop.includes(rule.customerDetails)) top.value.focus();
                    else if (healthMiddle.includes(rule.customerDetails)) middle.value.focus();
                    else if (healthLast.includes(rule.customerDetails)) last.value.focus();
                    else if(familyRef.value) familyRef.value.focus();
                }
            })
        }

        const goLists = (customer_id: number) => {
            router.replace({ name: 'CustomerDetails', params: {customer_id} })
        }

        const addNewMember = () => {
            let relationship = <any>null
            if(health.value.insured_type_id == 7) relationship = "Referral"

            health.value?.members?.unshift({
                id: null,
                insured_type_id: null,
                insured_city: null,
                member_name: null,
                relationship,
                gender: null,
                dob: null,
                nationality_id: null,
                is_salary: null,
                declaration_answers: [],
                is_married: false
            })
        }

        
        onMounted(async () => {
            reset();
            setCurrentPageBreadcrumbs("Add New Lead", [{ label: "Leads & Tasks", value: "CustomerLists" }]);
            await CustomerDetails()

            health.value.expected_insurance_start_date = common.getToday()

        })

        const cd = computed(() => {
            return store.getters.getCustomer
        })

        const CustomerDetails = async(is_loaded = false) => {
            const params = {customer_id: parseInt(router.currentRoute.value.params.customer_id.toString())}
            if(is_loaded) {
                params['is_loaded'] = true
            }

            await Customers.details(params)
            // await Customers.getCustomerById(params)
            const { customer_id, name, phone_country_code, phone_number, email, dob,gender,nationality_id,is_salary, has_group_leads } = cd.value
            health.value.id = customer_id
            health.value.name = name
            health.value.phone_country_code = phone_country_code
            health.value.phone_number = phone_number
            health.value.email = email
            health.value.dob = dob
            health.value.gender = gender
            health.value.nationality_id = nationality_id
            health.value.is_salary = is_salary

            if(has_group_leads)
                hideEmployeeType.value = false;
            // Customers.setHealth({
            //     id: customer_id,
            //     name,
            //     phone_country_code,
            //     phone_number,
            //     email,
            //     dob,
            //     gender,
            //     nationality_id,
            //     is_salary,
            // })
        }

        return {
            etc,
            rules,
            health,
            loading,
            ruleFormRef,
            clickLoading,
            submitHealth,
            addNewMember,
            top,
            middle,
            last,
            familyRef,
            hideEmployeeType
        }
    },
})
